/* tslint:disable */
export const labels = {
  WELCOME: 'WELCOME',
  TITLE_REGISTRATION_FORM: 'REGISTRATION FORM',
  LABEL_REGISTRATION_FORM_FIRST_NAME: 'First Name',
  LABEL_REGISTRATION_FORM_LAST_NAME: 'Last Name',
  LABEL_REGISTRATION_FORM_DOB: 'Date of Birth',
  LABEL_REGISTRATION_FORM_CARD_ID: 'Card ID',
  LABEL_REGISTRATION_FORM_RXGRP: 'Rx GRP',
  LABEL_REGISTRATION_FORM_CONTACT_NUMBER: 'CONTACT NUMBER',
  LABEL_REGISTRATION_FORM_EMAIL: 'E-mail',
  LABEL_REGISTRATION_FORM_REGISTER_BUTTON: 'REGISTER',
  TITLE_REGISTRATION_FORM_TERMS_OF_USE: 'TERMS OF USE',
  LABEL_REGISTRATION_FORM_TERMS_OF_USE_VIEW: 'View',
  TEXT_REGISTRATION_FORM_TERMS_OF_USE:
    'Read and accept the Terms of Use to continue using the Application',
  TEXT_PLANNED_OUTAGE_MSG: 'There might be a planned maintenance. The app services may not be available during this time. Sorry for the inconvenience.',
  LABEL_PLANNED_OUTAGE_BUTTON: 'OK',
  LABEL_REGISTRATION_FORM_CHECKBOX: 'I agree to the Terms of Use',
  TEXT_REGISTRATION_FORM_ALREADY_USER: 'Already an User?',
  LINK_REGISTRATION_FORM_LOGIN: 'Login',
  ERROR_REGISTRATION_FORM_FIRST_NAME_REQUIRED: 'First name is required',
  ERROR_REGISTRATION_FORM_LAST_NAME_REQUIRED: 'Last name is required',
  ERROR_REGISTRATION_FORM_DOB_REQUIRED: 'DOB is required',
  ERROR_REGISTRATION_FORM_CARD_ID_REQUIRED: 'Card-ID name is required',
  ERROR_REGISTRATION_FORM_CONTACT_NUMBER_REQUIRED: 'Contact number is required',
  ERROR_REGISTRATION_FORM_EMAIL_REQUIRED: 'Email is required',
  ERROR_REGISTRATION_FORM_EMAIL_VALIDATION: 'Please enter valid E-mail',
  ERROR_REGISTRATION_FORM_RXGRP_REQUIRED: 'Rx Grp is required',
  ERROR_REGISTRATION_FORM_TEXT_FIELDS_MAX_LENGTH:
    'Maximum 50 characters permitted',
  ERROR_REGISTRATION_FORM_TEXT_FIELDS_MIN_LENGTH:
    'Minimum 3 characters required',
  ERROR_REGISTRATION_FORM_TEXT_FIELDS_ONLY_ALPHABET:
    'Name must only contains alphabet',
  ERROR_REGISTRATION_FORM_CONTACT_NUMBER_VALIDATION:
    'Please enter valid number',
  ERROR_REGISTRATION_FORM_CHECKBOX_REQUIRED:
    'You must accept the Terms and Conditions',
  ERROR_LOGIN_FORM_EMAIL_REQUIRED: 'E-mail is required',
  ERROR_LOGIN_FORM_EMAIL_VALIDATION: 'Please enter valid E-mail',
  ERROR_LOGIN_FORM_PASSWORD_REQUIRED: 'Password is required',
  LABEL_LOGIN_FORM_REGISTERED_EMAIL: 'Registered Email',
  LABEL_LOGIN_FORM_PASSWORD: 'Password',
  TITLE_LOGIN_FORM: 'LOGIN',
  LABEL_LOGIN_FORM_LOGIN_LINK: 'Forgot Password?',
  LABEL_LOGIN_FORM_NOT_REGISTERED_TEXT: 'Not Registered?',
  LINK_LOGIN_FORM_REGISTER: 'Register',
  TITLE_CREATE_PROFILE: 'Create Profile',
  TITLE_CREATE_PROFILE_CONGRATULATIONS: 'Congratulations!',
  TEXT_CREATE_PROFILE_CONGRATULATIONS: 'You are successfully registered',
  TITLE_CREATE_PROFILE_FORM: 'Please create a password',
  LABEL_CREATE_PROFILE_FORM_EMAIL: 'Registered Email',
  LABEL_CREATE_PROFILE_FORM_PASSWORD: 'Password',
  LABEL_CREATE_PROFILE_FORM_CONFIRM_PASSWORD: 'Confirm Password',
  PLACEHOLDER_CREATE_PROFILE_FORM_PASSWORD: 'Password',
  PLACEHOLDER_CREATE_PROFILE_FORM_CONFIRM_PASSWORD: 'Confirm Password',
  ERROR_CREATE_PROFILE_FORM_PASSWORD_REQUIRED: 'Password is required',
  ERROR_CREATE_PROFILE_FORM_CONFIRM_PASSWORD_REQUIRED:
    'Confirm password is required',
  ERROR_CREATE_PROFILE_FORM_PASSWORD_INVALID: 'Please enter valid password',
  ERROR_CREATE_PROFILE_FORM_CONFIRM_PASSWORD: 'Password must match',
  BUTTON_CREATE_PROFILE_CONTINUE: 'Continue',
  TEXT_CREATE_PROFILE_PASSWORD_LENGTH: 'Password must match',
  TEXT_CREATE_PROFILE_PASSWORD_HAVE_UPPERCASE:
    'Password must have uppercase letter',
  TEXT_CREATE_PROFILE_PASSWORD_HAVE_DIGIT: 'Password must have a digit',
  TEXT_CREATE_PROFILE_PASSWORD_HAVE_SYMBOL:
    'Password must have special symbols',
  LABEL_LOGIN_FORM_BUTTON: 'Login',
  TITLE_SELECT_LANGUAGE: 'SELECT LANGUAGE',
  LABEL_SELECT_LANGUAGE_ENGLISH: 'English',
  LABEL_SELECT_LANGUAGE_SPANISH: 'Spanish',
  BUTTON_SELECT_LANGUAGE_CONTINUE: 'Continue',
  TITLE_PRESCRIPTION_TEXT: 'PRESCRIPTIONS',
  TEXT_PRESCRIPTIONS_HISTORY: 'HISTORY',
  LABEL_BOTTOM_TABS_HOME: 'Home',
  LABEL_BOTTOM_TABS_ORDERS: 'Orders',
  LABEL_BOTTOM_TABS_PRESCRIPTIONS: 'Prescriptions',
  LABEL_BOTTOM_TABS_DRUG_SEARCH: 'Drugs',
  LABEL_DRUG_PRICING_REJECT_LEGEND: "This drug is not covered in the current plan",
  LABEL_BOTTOM_TABS_PHARMACY_SEARCH: 'Pharmacies',
  LABEL_BOTTOM_TABS_PHARMACY_CLAIMS: 'Claims History',
  LABEL_BOTTOM_TABS_PHARMACY_MEMBERSHIP: 'Family Profiles',
  LABEL_MEMBERSHIP_NOT_APPLICABLE: "Not Applicable",
  LABEL_BOTTOM_TABS_PHARMACY_BENEFITS: 'Benefits',
  LABEL_BOTTOM_TABS_PHARMACY_SETTINGS: 'Settings',
  LABEL_BOTTOM_TABS_PHARMACY_HELP: 'Help',
  LABEL_BOTTOM_TABS_MORE: 'More',
  LABEL_PRESCRIPTION_ROUTER_DETAILS: 'DETAILS',
  LABEL_PRESCRIPTION_ROUTER_HISTORY: 'HISTORY',
  LABEL_PRESCRIPTIONS_RECENT_PRESCRIPTION: 'RECENT PRESCRIPTIONS',
  LABEL_PRESCRIPTIONS_SCREEN_REFILL: 'REFILL',
  LABEL_PRESCRIPTIONS_SCREEN_FILTER: 'Filter',
  LABEL_PRESCRIPTIONS_SCREEN_FILTER_BY_DATE: 'Filter by Date',
  LABEL_PRESCRIPTIONS_SCREEN_FILTER_LAST_SIX_MONTHS: 'Last 6 Months',
  LABEL_PRESCRIPTIONS_SCREEN_FILTER_BY_STATUS: 'Filter by Status',
  TEXT_PRESCRIPTIONS_SCREEN_DAYS: 'days',
  TEXT_PRESCRIPTIONS_SCREEN_NO_PRESCRIPTIONS: 'You have no prescriptions',
  LABEL_PRESCRIPTIONS_SCREEN_RENEW: 'RENEW',
  TITLE_PRESCRIPTION_HISTORY_TEXT: 'HISTORY',
  TEXT_PRESCRIPTION_HISTORY_INACTIVE: 'Inactive',
  TEXT_PRESCRIPTION_HISTORY_ACTIVE: 'Active',
  TITLE_PRESCRIPTION_DETAIL_TEXT: 'DETAILS',
  TITLE_PRESCRIPTION_DETAILS: 'PRESCRIPTION DETAILS',
  LABEL_PRESCRIPTION_DETAILS_PATIENT_NAME: 'Patient Name',
  LABEL_PRESCRIPTION_DETAILS_RX_NUMBER: 'Rx Number',
  LABEL_PRESCRIPTION_DETAILS_PATIENT_DOB: 'Patient DOB',
  LABEL_PRESCRIPTION_DETAILS_PRESCRIBER_NAME: 'Prescriber Name',
  LABEL_PRESCRIPTION_DETAILS_PRESCRIBED_DRUG: 'Prescribed Drug',
  LABEL_PRESCRIPTION_DETAILS_DIRECTIONS: 'Directions',
  LABEL_PRESCRIPTION_DETAILS_DISPENSED_DRUG: 'Dispensed Drug',
  LABEL_PRESCRIPTION_DETAILS_RX_DATE: 'Rx Date',
  LABEL_PRESCRIPTION_DETAILS_DAW: 'DAW',
  LABEL_PRESCRIPTION_DETAILS_EXPIRATION_DATE: 'Expiration Date',
  LABEL_PRESCRIPTION_DETAILS_DRUG_DEA_CLASS: 'Drug DEA Class',
  LABEL_PRESCRIPTION_DETAILS_SUPPLY_DAYS: 'Days Supply',
  LABEL_PRESCRIPTION_DETAILS_QUANTITY: 'Quantity',
  LABEL_PRESCRIPTION_DETAILS_DISPENSED_DATE: 'Dispensed Date',
  LABEL_PRESCRIPTION_DETAILS_PRESCRIBED_DRUG_NAME: 'Prescribed Drug Name',
  LABEL_PRESCRIPTION_DETAILS_DISPENSED_DRUG_NAME: 'Dispensed Drug Name',
  LABEL_PRESCRIPTION_DETAILS_DAW_LIST_OF_VALUES: 'DAW - List of Values',
  LABEL_PRESCRIPTION_DETAILS_NXT_REFILL_DATE: 'Next Refill Date',
  LABEL_PRESCRIPTION_DETAILS_REFILL_DATE: 'Refill Date',
  LABEL_PRESCRIPTION_DETAILS_NUM_REFILLS: 'Number of Refills Allowed',
  LABEL_PRESCRIPTION_DETAILS_FILLABLE_STATUS: 'Fillable Status',
  LABEL_PRESCRIPTION_DETAILS_STATUS: 'Status',
  LABEL_AUTO_REFILL: 'Auto Reminder',
  LABEL_PRESCRIPTION_DETAILS_REFILLS_REMAINING: 'Number of Refills Remaining',
  LABEL_PRESCRIPTION_DETAILS_REFILL_BUTTON: 'REFILL',
  LABEL_HOME_CURRENT_BALANCE: 'Mail Order Balance',
  LABEL_HOME_MORE_DETAILS: 'MORE DETAILS',
  LABEL_HOME_COPAY: 'Copay',
  LABEL_HOME_ORDER: 'Order',
  LABEL_HOME_PAYMENT: 'Payment',
  LABEL_HOME_IN_PROGRESS: 'In Progress',
  LABEL_HOME_IN_SHIPPED: 'Shipped',
  LABEL_HOME_DELIVERED: 'Delivered',
  LABEL_HOME_PATIENT_PAY: 'Patient Pay',
  LABEL_CLAIMS_CLAIM_ID: 'Claim ID',
  TITLE_HOME_ORDER: 'ORDER',
  TITLE_HOME_ORDER_NUMBER: 'Order Number',
  TITLE_HOME_RECENT_ORDER: 'RECENT ORDERS',
  LABEL_HOME_RECEIVED_DATE: 'Received Date',
  LABEL_HOME_VIEW_ALL: 'View All',
  LABEL_HOME_VIEW_ID: 'VIEW ID',
  TABLE_PRESCRIPTION_DRUG_NAME: 'Drug Name',
  LABEL_BIOMETRIC_FAILED: 'Failed to enable biometric authentication',
  LABEL_NO_ORDERS: 'You have no recent orders',
  TITLE_HOME_PRESCRIPTIONS: 'PRESCRIPTIONS',
  TITLE_HOME_CLAIMS: 'CLAIMS HISTORY',
  // LABEL_HOME_NO_CLAIMS: 'You have no past claims',
  LABEL_HOME_NO_PRESCRIPTIONS: 'You have no prescriptions.',
  BUTTON_HOME_DRUG_SEARCH: 'Drug Search',
  BUTTON_HOME_FIND_PHARMACY: 'Find Pharmacy',
  BUTTON_LOGOUT: 'Logout',
  HOME_ERROR_SORRY: 'Sorry, something went wrong',
  PRESCRIPTION_EMPTY_HEALTH: 'Looks like you\u2019re in great health',
  PRESCRIPTION_EMPTY: 'You have no recent prescriptions',
  NO_CLAIMS: 'No claims found for the selected filter. Please try using different filter options.',
  NO_ORDERS: 'You have no active orders',
  FILTER_ALL: 'All',
  FILTER_BUTTON_APPLY: 'Apply',
  FILTER_BUTTON_CLEAR: 'Clear',
  NO_DATA_MATCH: 'No data matching the filter',
  LABEL_HOME_DAYS: 'Days',
  LABEL_HOME_BIOMETRIC_ALERT_FACE_ID:
    'Do you want to enable login using Face ID?',
  LABEL_BIOMETRIC_PROMPT: 'Verify Identity to Unlock',
  LABEL_HOME_YES: 'Yes',
  LABEL_HOME_NO: 'No',
  SELECT_LANGUAGE: 'SELECT LANGUAGE',
  SELECT_MEMBER: 'Select Member',
  RECENT: 'RECENT',
  LABEL_HOME_BIOMETRIC_ALERT_TOUCH_ID:
    'Do you want to enable login using Touch ID?',
  TEXT_NO_PRESCRIPTIONS_FOUND: "We couldn't find any prescriptions",
  TITLE_LIST_CLAIMS_SCREEN: 'CLAIMS HISTORY',
  LABEL_LIST_CLAIMS_RX_FILL_DATE: 'Rx Fill Date',
  LABEL_LIST_CLAIMS_COPAY: 'Copay',
  LABEL_LIST_CLAIMS_PATIENT_PAY: 'Patient Pay',
  LABEL_LIST_CLAIMS_PRESCRIBER_NAME: 'Prescriber Name',
  LABEL_NO_PAST_CLAIMS: 'You have no past claims',
  TITLE_FILTER_AND_SORT_MODAL: 'Filter & Sort',
  LABEL_FILTER_AND_SORT_APPLY: 'APPLY',
  LABEL_FILTER_AND_SORT_CLEAR: 'CLEAR',
  LABEL_FILTER_AND_SORT_BY_DATE: 'Filter by Date',
  LABEL_FILTER_AND_SORT_BY_ALPHABET: 'Sort by Alphabet',
  LABEL_FILTER_AND_SORT_BY_SIX_MONTHS: 'Last 6 Months',
  LABEL_FILTER_AND_SORT_BY_ONE_YEAR: 'Last 1 Year',
  TITLE_CLAIM_DETAILS_SCREEN: 'CLAIM DETAILS',
  LABEL_CLAIM_DETAILS_CLAIM_ID: 'Claim ID',
  LABEL_CLAIM_DETAILS_RX_NUMBER: 'Rx Number',
  LABEL_CLAIM_DETAILS_DRUG_NAME: 'Drug Name',
  LABEL_CLAIM_DETAILS_PRESCRIBER_NAME: 'Prescriber Name',
  LABEL_CLAIM_DETAILS_PHARMACY_NAME: 'Pharmacy',
  LABEL_CLAIM_DETAILS_STATUS: 'Status',
  LABEL_CLAIM_DETAILS_RX_FILL_DATE: 'Rx Fill Date',
  LABEL_CLAIM_DETAILS_PROCESSED_DATE: 'Processed Date',
  LABEL_CLAIM_DETAILS_COPAY: 'Copay',
  LABEL_CLAIM_DETAILS_PATIENT_PAY: 'Patient Pay',
  LABEL_CLAIM_DETAILS_QUANTITY: 'Quantity',
  LABEL_CLAIM_DETAILS_WRITTEN_DATE: 'Written Date',
  LABEL_CLAIM_DETAILS_COMPOUND: 'Compound',
  LABEL_CLAIM_DETAILS_DAYS_SUPPLY: 'Days Supply',
  LABEL_CLAIM_DETAILS_NPI: 'Pharmacy NPI',
  LABEL_CLAIM_DETAILS_DAW: 'DAW',
  LABEL_CLAIM_DETAILS_PHARMA_ADDRESS: 'Pharmacy Address',
  LABEL_CLAIM_DETAILS_PRESCRIBER_NPI: 'Prescriber NPI',
  LABEL_CLAIM_DETAILS_NDC: 'NDC',
  LABEL_CLAIM_DETAILS_SPECIALTY_INDICATOR: 'Specialty Indicator',
  LABEL_CLAIM_DETAILS_DRUG_INFO: 'Drug Information',
  LABEL_CLAIM_DETAILS_FORMULARY_TIER: 'Formulary Tier',
  LABEL_CLAIM_DETAILS_MEDICATION: 'Medication',
  LABEL_CLAIM_DETAILS_BRAND: 'Brand',
  LABEL_CLAIM_DETAILS_ROUTE_ADMIN: 'Route of Admin',
  LABEL_CLAIM_DETAILS_CLIENT_PAY: 'Total Client Pay',
  LABEL_CLAIM_DETAILS_NON_PRIMARY: 'Processed as Non-Primary',
  LABEL_CLAIM_DETAILS_REIMBURSED_AMOUNT: 'Reimbursement Amount',
  LABEL_CLAIM_DETAILS_ADDITIONAL_MESSAGE: 'Additional Message',
  LABEL_BENEFITS_CAPS_ACCUMULATION_MOP: 'MOOP',
  LABEL_BENEFITS_CAPS_ACCUMULATION_MPP: 'MPP',
  LABEL_BENEFITS_CAPS_ACCUMULATION_DEDUCTIBLE: 'Deductible',
  LABEL_COMMON_NO_DATA: 'No data found',
  TITLE_BENEFITS_COPAY_SCREEN: 'COPAY',
  TITLE_BENEFITS_MAIN_SCREEN: 'BENEFITS',
  TITLE_BENEFITS_CAPS_ACCUMULATION_SCREEN: 'MOOP/DEDUCTIBLE',
  TITLE_BENEFITS_CATEGORY_COVERAGE_SCREEN: 'CATEGORY COVERAGE',
  TITLE_BENEFITS_EOB_SCREEN: 'EXPLANATION OF BENEFIT',
  LABEL_BENEFITS_MAIN_CAPS_ACCUMULATION: 'Max Out of Pocket/Deductible',
  LABEL_BENEFITS_MAIN_COPAY: 'Copay',
  LABEL_BENEFITS_MAIN_CATEGORY_COVERAGE: 'Category Coverage',
  LABEL_BENEFITS_MAIN_MEDICATION_LIST: 'Preferred Medication List',
  LABEL_BENEFITS_MAIN_EOB: 'Explanation of Benefit',
  TITLE_BENEFITS_PREFERRED_MEDICATION_LIST: 'PREFERRED MEDICATION LIST',
  LABEL_DEDUCTIBLE_ALIAS: 'Alias',
  LABEL_DEDUCTIBLE_CAP_TYPE: 'Cap Type',
  LABEL_DEDUCTIBLE_FREQUENCY: 'Frequency',
  LABEL_DEDUCTIBLE_FAMILY_MEMBER_LIMIT: 'Family Member Limit Amount',
  LABEL_DEDUCTIBLE_FAMILY_LIMIT: 'Family Limit Amount',
  LABEL_DEDUCTIBLE_FAMILY_MEMBER_ACCUMULATION: 'Member Accumulation/Limit',
  LABEL_DEDUCTIBLE_FAMILY_ACCUMULATION: 'Family Accumulation/Limit',
  LABEL_DEDUCTIBLE_SINGLE_LIMIT: 'Single Limit',
  LABEL_DEDUCTIBLE_SINGLE_ACCUMULATION: 'Single Accumulation/Limit',
  LABEL_ORDER_PAST_ORDERS: 'PAST ORDERS',
  LABEL_DEDUCTIBLE_GROUP_NAME: 'Client Group Name',
  LABEL_CLAIM_DETAILS_PRICING_INFORMATION: 'Pricing Information',
  LABEL_CLAIM_DETAILS_STATUS_INFORMATION: 'Status Information',
  LABEL_CLAIM_DETAILS_DRUG_INFORMATION: 'Drug Information',
  LABEL_CLAIM_DETAILS_CLAIM_INFORMATION: 'Claim Information',
  LABEL_ORDER_PICKED_UP: 'Picked-Up',
  TITLE_BENEFITS_PREFERRED_MEDICATION_PDF: 'PDF',
  LABEL_COMMON_NO_DATA_AVAILABLE: 'No data available',
  LABEL_FIND_PHARMACY_SUBMIT_BUTTON: 'FIND PHARMACY',
  TITLE_FIND_PHARMACY_SCREEN: 'FIND PHARMACY',
  LABEL_FIND_PHARMACY_ZIP_CODE: 'ZIP Code',
  LABEL_FIND_PHARMACY_RADIUS: 'Distance',
  LABEL_FIND_PHARMACY_MILES: 'Miles',
  LABEL_FIND_PHARMACY_OR: 'OR',
  LABEL_FIND_PHARMACY_CITY: 'City',
  LABEL_FIND_PHARMACY_STATE: 'State',
  PLACEHOLDER_FIND_PHARMACY_STATE: 'Select a state',
  LABEL_FIND_PHARMACY_PHARMACY_NAME: 'Pharmacy Name',
  TITLE_LIST_PHARMACY_SCREEN: 'PHARMACY LISTING',
  TITLE_LISTING_FILTER_DEFAULT: 'Listing defaulted to In-Network',
  LABEL_LIST_PHARMACY_LOCATION: 'Location',
  ERROR_LIST_PHARMACY_NO_PHARMACIES: 'Sorry, no pharmacies found',
  LABEL_LIST_PHARMACY_SORT_BY_DISTANCE: 'Sort by Distance',
  VALUES_LIST_PHARMACY_SORT_BY_DISTANCE_DROPDOWN_NONE: 'None',
  VALUES_LIST_PHARMACY_SORT_BY_DISTANCE_DROPDOWN: 'Nearest First',
  LABEL_LIST_PHARMACY_FILTER_BY_RADIUS: 'Filter by Distance',
  LABEL_COMMON_FILTER_APPLY: 'Apply',
  LABEL_COMMON_FILTER_CLEAR: 'CLEAR',
  TITLE_COMMON_FILTER_MODAL: 'Filter & Sort',
  LABEL_LIST_PHARMACY_FILTER_BY_IN_NETWORK: 'In Network',
  LABEL_LIST_PHARMACY_FILTER_BY_HRS_OPERATION: '24 Hours of Operation',
  ERROR_FIND_PHARMACY: 'Please check the entries again',
  WARNING_FIND_PHARMACY: 'Please enter valid zip code or city',
  WARNING_FIND_PHARMACY_PHARMACY_NAME: 'Please enter valid pharmacy name',
  BUTTON_CART_ADDRESS_CONTINUE: 'Continue',
  BUTTON_CART_HOME_CHECKOUT: 'Checkout',
  BUTTON_CART_PAYMENT_CONTINUE: 'Continue',
  BUTTON_CART_SUMMARY_HOME: 'Home',
  BUTTON_CART_SUMMARY_VIEW_ORDERS: 'VIEW ORDERS',
  BUTTON_MAIL_ORDER_PAST_ORDERS: 'Past Orders',
  BUTTON_MEMBERSHIP_CARD_ADD_TO_WALLET: 'add to wallet',
  ERROR_ADD_NEW_ADDRESS_ADDRESS_LINE_1: 'Please enter a valid value',
  ERROR_ADD_NEW_ADDRESS_ADDRESS_LINE_2: 'Please enter a valid value',
  ERROR_ADD_NEW_ADDRESS_CARE_OF_NAME: 'Please enter a valid value',
  ERROR_ADD_NEW_ADDRESS_CITY: 'Please enter a valid value',
  ERROR_ADD_NEW_ADDRESS_REQUIRED: 'Please fill in this field',
  ERROR_ADD_NEW_ADDRESS_STATE: 'Please enter a valid value',
  ERROR_ADD_NEW_ADDRESS_ZIPCODE: 'Please enter a valid value',
  ERROR_COMMON_SOMETHING_WENT_WRONG: 'Sorry, something went wrong',
  ERROR_VIEW_IN_MAPS_NO_APP_FOUND: 'No app found',
  LABEL_ADD_NEW_ADDRESS_ADDRESS_LINE_1: 'Address Line 1',
  LABEL_ADD_NEW_ADDRESS_ADDRESS_LINE_2: 'Address Line 2',
  LABEL_ADD_NEW_ADDRESS_CARE_OF_NAME: 'C/O Name',
  LABEL_ADD_NEW_ADDRESS_CITY: 'City',
  LABEL_ADD_NEW_ADDRESS_FAILED: 'Sorry, something went wrong',
  LABEL_ADD_NEW_ADDRESS_STATE: 'State',
  LABEL_ADD_NEW_ADDRESS_SUBMIT: 'CONFIRM',
  LABEL_ADD_NEW_ADDRESS_SUCCESS: 'Address added successfully',
  LABEL_ADD_NEW_ADDRESS_ZIPCODE: 'ZIP Code',
  LABEL_CART_ADDRESS_ADD_ADDRESS: 'ADD NEW ADDRESS',
  LABEL_CART_ADDRESS_ADD_ADDRESS_TO_CONTINUE: 'ADD ADDRESS TO CONTINUE',
  LABEL_CART_ADDRESS_ADDRESS: 'Address',
  LABEL_CART_ADDRESS_CARE_OF_NAME: 'Care of Name',
  LABEL_CART_ADDRESS_DEFAULT_ADDRESS: 'Default Address',
  LABEL_CART_ADDRESS_PICK_UP: 'Pickup Address',
  LABEL_CART_ADDRESS_PICKUP_FROM_PHARMACY: 'Pickup from Benecard Pharmacy',
  LABEL_CART_ADDRESS_SELECT_ADDRESS: 'Select a Shipping Address',
  LABEL_CART_HOME_CART_ITEMS: 'Cart Items',
  LABEL_CART_HOME_EMPTY: 'Your cart is empty',
  LABEL_CART_PAYMENT_CALL_US: 'Please call us on',
  LABEL_CART_PAYMENT_HELP_TEXT: 'Want to add a new Payment Method?',
  LABEL_CART_PAYMENT_PHONE_NUMBER: '(123) 123-4567',
  LABEL_CART_PAYMENT_SELECT_PAYMENT: 'Select a Payment Method',
  LABEL_CART_SUMMARY_ORDER_PLACED: 'Your order has been successfully placed',
  LABEL_COMMON_MILES: 'Miles',
  LABEL_COMMON_OPEN_24_HOURS: 'Open 24 hours',
  LABEL_COMMON_SLIDER_RADIUS: 'Distance',
  LABEL_COMMON_UNKNOWN_WORKING_HOURS: 'Unknown working hours',
  LABEL_CONFIRM_ORDER_CART_ITEMS: 'Cart Items',
  LABEL_CONFIRM_ORDER_CONFIRM_BUTTON: 'CONFIRM AND PLACE ORDER',
  LABEL_CONFIRM_ORDER_PAYMENT_METHOD: 'Payment Method',
  LABEL_PAY_BY_CHEQUE_TITLE: 'Other',
  LABEL_CONFIRM_ORDER_PICKUP_ADDRESS: 'Pickup Address',
  LABEL_CONFIRM_ORDER_TITLE: 'CONFIRM ORDER',
  LABEL_CUSTOMER_SUPPORT_PREFIX: 'Technical Support',
  LABEL_DRUG_PRICING_CITY: 'City',
  LABEL_DRUG_PRICING_DAYS_OF_SUPPLY: 'Days Supply',
  LABEL_DRUG_PRICING_DETAILS_DAYS: 'Days',
  LABEL_DRUG_PRICING_DETAILS_MEMBER_COST: 'Member Cost',
  LABEL_DRUG_PRICING_DETAILS_PHARMACY: 'Pharmacy Details',
  LABEL_DRUG_PRICING_DETAILS_PLAN_COST: 'Plan Cost',
  LABEL_DRUG_PRICING_DETAILS_TOTAL_COST: 'Total Cost',
  LABEL_DRUG_PRICING_HIGHEST_PRICE: 'Highest',
  LABEL_DRUG_PRICING_LIST_LOCATION: 'Location',
  LABEL_DRUG_PRICING_LOWEST_PRICE: 'Lowest',
  LABEL_DRUG_PRICING_MODAL_EDIT: 'EDIT',
  LABEL_DRUG_PRICING_MODAL_SUBMIT: 'SUBMIT',
  LABEL_DRUG_PRICING_NO_PHARMACIES_FOUND: 'No pharmacies found',
  LABEL_DRUG_PRICING_OR: 'OR',
  LABEL_DRUG_PRICING_PHARMACY_NAME: 'Pharmacy Name',
  LABEL_DRUG_PRICING_QUANTITY: 'Quantity',
  LABEL_DRUG_PRICING_SORT_BY_PRICING: 'Sort By Price',
  LABEL_DRUG_PRICING_ZIP_CODE: 'ZIP Code',
  LABEL_DRUG_SEARCH_DISCLAIMER: 'Disclaimer',
  LABEL_DRUG_SEARCH_DRUG_INTERACTIONS: 'Drug Interactions ',
  LABEL_DRUG_SEARCH_NOTES: 'Notes',
  LABEL_DRUG_SEARCH_OVERDOSE: 'Overdose',
  LABEL_DRUG_SEARCH_PRECAUTIONS: 'Precautions',
  LABEL_DRUG_SEARCH_PRICING: 'Drug Pricing',
  LABEL_DRUG_SEARCH_SEARCH: 'Drug Search',
  LABEL_DRUG_SEARCH_SIDE_EFFECTS: 'Side Effects',
  LABEL_DRUG_SEARCH_USES: 'Uses (Primary Indication)',
  LABEL_DRUG_PDF_FILE_NAME: "Drug Monograph Form",
  LABEL_PRESCRIPTION_PDF_FILE_NAME: "Prescription Details Form",
  LABEL_FAQ_FAILED: 'Sorry, something went wrong',
  LABEL_HOME_BIOMETRIC_ALERT: 'Do you want to enable biometric authentication?',
  LABEL_IN_PROGRESS: 'In Progress',
  LABEL_INACTIVE: 'Inactive',
  LABEL_MAIL_ORDER_DETAILS_DAYS_SUPPLY: 'Days Supply',
  LABEL_MAIL_ORDER_DETAILS_DOB: 'Patient DOB',
  LABEL_MAIL_ORDER_DETAILS_DRUG_NAME: 'Drug Name',
  LABEL_MAIL_ORDER_DETAILS_ORDER_NUMBER: 'Order Number',
  LABEL_MAIL_ORDER_DETAILS_ORDER_STATUS: 'Order Status',
  LABEL_MAIL_ORDER_DETAILS_PATIENT_NAME: 'Patient Name',
  LABEL_MAIL_ORDER_DETAILS_PHARMACY: 'Pharmacy',
  LABEL_MAIL_ORDER_DETAILS_QUANTITY: 'Quantity',
  LABEL_MAIL_ORDER_DETAILS_RECEIVED_DATE: 'Received Date',
  LABEL_MAIL_ORDER_DETAILS_RX_NUMBER: 'Rx Number',
  LABEL_MAIL_ORDER_DETAILS_SHIPMENT_CARRIER: 'Shipment Carrier',
  LABEL_MAIL_ORDER_DETAILS_SHIPMENT_DATE: 'Shipment Date',
  LABEL_MAIL_ORDER_DETAILS_SHIPMENT_METHOD: 'Shipment Method',
  LABEL_MAIL_ORDER_DETAILS_SHIPMENT_NUMBER: 'Shipment Number',
  LABEL_MAIL_ORDER_HISTORY_12: 'Last 1 Year',
  LABEL_MAIL_ORDER_HISTORY_6: 'Last 6 Months',
  LABEL_MAIL_ORDER_HISTORY_All: 'All',
  LABEL_MAIL_ORDER_HISTORY_APPLY: 'Apply',
  LABEL_MAIL_ORDER_HISTORY_CLEAR: 'Clear',
  LABEL_MAIL_ORDER_HISTORY_EARLIEST: 'Earliest First',
  LABEL_MAIL_ORDER_HISTORY_EMPTY: 'You have no past orders',
  LABEL_MAIL_ORDER_HISTORY_FILTER_DATE: 'Filter by Date',
  LABEL_MAIL_ORDER_HISTORY_FILTER_STATUS: 'Filter by Status',
  LABEL_MAIL_ORDER_HISTORY_OLDEST: 'Oldest First',
  'LABEL_MAIL_ORDER_HISTORY_Picked-Up': 'Picked-Up',
  LABEL_MAIL_ORDER_HISTORY_Shipped: 'Shipped',
  LABEL_MAIL_ORDER_HISTORY_SORT_NAME: 'Sort by Alphabet',
  LABEL_MAIL_ORDER_HOME_EMPTY: 'You have no active orders',
  LABEL_MAP_VIEW_IN_NETWORK: 'In-network Pharmacies',
  LABEL_MAP_VIEW_OTHERS: 'Other Pharmacies',
  LABEL_MEMBERSHIP_CARD_PASS_FAILED: 'Failed to add pass. Try again!',
  LABEL_MEMBERSHIP_CARD_PASS_SUCCESS: 'Card added to the wallet',
  LABEL_MEMBERSHIP_DEDUCTIBLE: 'Deductible',
  LABEL_MEMBERSHIP_DETAILS_DOB: 'Date of Birth',
  LABEL_MEMBERSHIP_DETAILS_GENDER: 'Gender',
  LABEL_MEMBERSHIP_DETAILS_ID: 'ID Card',
  LABEL_MEMBERSHIP_DETAILS_NAME: 'Name',
  LABEL_MEMBERSHIP_DETAILS_PERSON_CODE: 'Person Code',
  LABEL_MEMBERSHIP_DETAILS_REL_CODE: 'Relationship Code',
  LABEL_MEMBERSHIP_DETAILS_REL_VALUE: 'Relationship Value',
  LABEL_MEMBERSHIP_MOP: 'MOOP',
  LABEL_MEMBERSHIP_TOTAL_BALANCE: 'Mail Order Balance',
  LABEL_MEMBERSHIP_VIEW_BENEFITS: "VIEW FAMILY PROFILES' BENEFITS",
  LABEL_MORE_ALTERNATE_THERAPY: 'Alternate Therapy',
  LABEL_MORE_BENEFITS: 'Benefits',
  LABEL_MORE_CLAIMS: 'Claims History',
  LABEL_MORE_HELP: 'Help',
  LABEL_MORE_LOGOUT: 'Logout',
  LABEL_MORE_MEMBERSHIP: 'Family Profiles',
  LABEL_MORE_ORDERS: 'Orders',
  LABEL_MORE_SETTINGS: 'Settings',
  LABEL_NO_MORE_REFILLS: 'No More Refills',
  LABEL_PHARMACY_DETAILS_ADDRESS: 'Pharmacy Address',
  LABEL_PHARMACY_DETAILS_DISTANCE: 'Distance',
  LABEL_PHARMACY_DETAILS_DISTANCE_UNIT: 'Miles',
  LABEL_PHARMACY_DETAILS_FRIDAY: 'Friday',
  LABEL_PHARMACY_DETAILS_HOURS: 'Hours of Operation',
  LABEL_PHARMACY_DETAILS_IN_NETWORK: 'In Network Pharmacy',
  LABEL_PHARMACY_DETAILS_MONDAY: 'Monday',
  LABEL_PHARMACY_DETAILS_NABP: 'NABP',
  LABEL_PHARMACY_DETAILS_NAME: 'Pharmacy Name',
  LABEL_PHARMACY_DETAILS_NPI: 'NPI',
  LABEL_PHARMACY_DETAILS_PHONE: 'Phone Number',
  LABEL_PHARMACY_DETAILS_SATURDAY: 'Saturday',
  LABEL_PHARMACY_DETAILS_SERVICES: 'Pharmacy Services',
  LABEL_PHARMACY_DETAILS_SUNDAY: 'Sunday',
  LABEL_PHARMACY_DETAILS_THURSDAY: 'Thursday',
  LABEL_PHARMACY_DETAILS_TUESDAY: 'Tuesday',
  LABEL_PHARMACY_DETAILS_WEDNESDAY: 'Wednesday',
  LABEL_REFILL: 'Refill',
  LABEL_REQUEST_RENEW_PRESCRIPTION: 'Renew',
  LABEL_SET_BIOMETRIC_NO: 'No',
  LABEL_SET_BIOMETRIC_PROMPT_CONTENT_FACEID:
    'Face ID enables biometric access to the EmpiRx App for the face registered on this device',
  LABEL_SET_BIOMETRIC_PROMPT_CONTENT_TOUCHID:
    'Touch ID enables biometric access to the EmpiRx App for the thumbprint registered on this device',
  LABEL_SET_BIOMETRIC_PROMPT_TITLE_FACEID: 'Configure Face ID?',
  LABEL_SET_BIOMETRIC_PROMPT_TITLE_TOUCHID: 'Configure Touch ID?',
  LABEL_SET_BIOMETRIC_SUCCESS_TEXT_FACEID:
    'You have successfully enabled Face ID based authentication',
  LABEL_SET_BIOMETRIC_SUCCESS_TEXT_TOUCHID:
    'You have successfully enabled Touch ID based authentication',
  LABEL_SET_BIOMETRIC_YES: 'Yes',
  LABEL_SET_BIOMETRICS_ENABLE_FACEID: 'Enable Face ID',
  LABEL_SET_BIOMETRICS_ENABLE_TOUCHID: 'Enable Touch ID',
  LABEL_SET_BIOMETRICS_ERROR: 'Sorry, something went wrong',
  LABEL_SETTINGS_PAYMENT_LIST_DEFAULT_TITLE: 'Default Payment Method',
  LABEL_SETTINGS_PAYMENT_LIST_OTHER_TITLE: 'Other Payment Methods',
  LABEL_SETTINGS_PAYMENT_LIST_SET_DEFAULT_PAYMENT: 'Set as Default',
  LABEL_SETTINGS_SHIPPING_ADDRESS_ADD_ADDRESS: 'ADD NEW ADDRESS',
  LABEL_SETTINGS_SHIPPING_ADDRESS_ERROR: 'Sorry, something went wrong',
  LABEL_SETTINGS_SHIPPING_ADDRESS_TITLE: 'Address',
  LABEL_SLIDE_UP: 'Tap to unlock',
  LABEL_TOO_SOON: 'Too Soon',
  LABEL_TRANSFERRED: 'Transferred',
  PICK_UP_PHARMACY_ADDRESS_LINE_1: 'Benecard Central Fill of PA',
  PICK_UP_PHARMACY_ADDRESS_LINE_2: '5040 Ritter Road, Mechanicsburg, PA 17050',
  PICK_UP_PHARMACY_TIMINGS: 'Hours 9am \u2013 5pm',
  PLACEHOLDER_DRUG_SEARCH_DRUG_NAME: 'Search by drug name',
  TEXT_DRUG_PRICING_DAYS_OF_SUPPLY_NONZERO_SELECTION_ERROR:
    'Please enter non-zero days supply',
  TEXT_DRUG_PRICING_DRUG_SELECTION_ERROR: 'Please select a drug',
  TEXT_DRUG_PRICING_QTY_NONZERO_ERROR: 'Please enter non-zero quantity',
  TEXT_DRUG_PRICING_ZIP_CODE_CITY_ERROR: 'Please enter either zipCode or City',
  TITLE_ADD_NEW_ADDRESS: 'ADD NEW ADDRESS',
  TITLE_ALTERNATE_THERAPY: 'ALTERNATE THERAPY',
  TITLE_CART_ADDRESS_HEADING: 'ADDRESS',
  TITLE_CART_HOME_HEADING: 'CART',
  TITLE_CART_PAYMENT_HEADING: 'PAYMENT',
  TITLE_CART_SUMMARY_ORDERED_ITEMS: 'Ordered Items',
  TITLE_CART_SUMMARY_PICKUP_ADDRESS: 'Pickup Address',
  TITLE_CART_SUMMARY_SHIPPING_ADDRESS: 'Shipping Address',
  TITLE_CUSTOMER_SUPPORT: 'CUSTOMER SUPPORT',
  TITLE_DRUG_INFO: 'DRUG INFORMATION',
  TITLE_DRUG_PRICING_LIST: 'DRUG PRICING LIST',
  TITLE_DRUG_PRICING_SUBMIT: 'SEARCH DRUG PRICING',
  TITLE_DRUG_SEARCH: 'DRUG SEARCH',
  TITLE_CART_SUMMARY_THANK_YOU: 'Thank You!',
  TITLE_MAIL_ORDER_DETAILS: 'ORDER DETAILS',
  TITLE_MAIL_ORDER_DETAILS_ORDER_TRACKING: 'Order Tracking',
  TITLE_MAIL_ORDER_DETAILS_SHIPPING_ADDRESS: 'Shipping Address',
  TITLE_MAIL_ORDER_HISTORY: 'PAST ORDERS',
  TITLE_MAIL_ORDER_HISTORY_FILTER_SORT: 'Filter & Sort',
  TITLE_MAIL_ORDER_HOME: 'ORDERS',
  TITLE_MEMBERSHIP_CARD: 'ID CARD',
  TITLE_MEMBERSHIP_DETAILS: 'DETAILS',
  TITLE_MEMBERSHIP_HEADING: 'FAMILY PROFILES',
  TITLE_MEMBERSHIP_MEMBERS: 'Members',
  TITLE_MORE: 'MORE',
  TITLE_PHARMACY_DETAILS: 'DETAILS',
  TITLE_SET_BIOMETRICS_FACEID: 'FACE ID',
  TITLE_SET_BIOMETRICS_TOUCHID: 'TOUCH ID',
  TITLE_SETTINGS_PAYMENT_LIST: 'PAYMENT METHODS',
  TITLE_SETTINGS_SHIPPING_ADDRESS: 'SHIPPING ADDRESSES',
  TITLE_TPA_FAQ: 'TPA FAQ',
  TOAST_ADDED_TO_CART: 'Item added to cart',
  LABEL_COMMON_FILTER_BY_MEMBER: 'Filter by Member',
  LABEL_COMMON_FILTER_BY_MEMBER_PLACEHOLDER: 'All',
  LABEL_COMMON_QUANTITY: 'QTY',
  LABEL_COMMON_DAYS_SUPPLY: 'DAYS',
  LABEL_RECENT_ORDERS: 'RECENT ORDERS',
  LABEL_COMMON_CARD_ID: 'Card ID',
  LABEL_DRUG_PRICING_LEGEND_GENERIC: 'Generic',
  LABEL_DRUG_PRICING_LEGEND_BRAND: 'Brand',
  TEXT_DRUG_PRICING_REQUIRED_ERROR: 'Please enter required fields',
  COMMON_NO_DATA_AVAILABLE: 'No data available',
  LABEL_BENEFITS_MAIL_ORDER_COPAY: 'Mail Order Copay',
  LABEL_BENEFITS_RETAIL_ORDER_COPAY: 'Retail Copay',
  LABEL_BENEFITS_ORDER_COPAY:'Copay',
  TEXT_SELECT_CART_ITEM_TOAST: 'Please select an item to continue',
  TEXT_DELETE_CART_ITEM_TOAST: 'Are you sure you want to delete this item?',
  TEXT_DRUG_SEARCH_BY_DRUG_NAME: 'Search by drug name',
  TEXT_DRUG_SEARCH_NO_DRUGS_FOUND: 'Search did not match any drugs',
  LABEL_EMPIRX_FAQS: 'EMPIRX FAQs',
  LABEL_NOTIFICATIONS_REFILL_REMAINDERS:
    'Allow email notifications for refill reminders',
  LABEL_NOTIFICATIONS_SHIPPING_UPDATE:
    'Allow email notifications for shipping updates',
  LABEL_NOTIFICATIONS_CLINICAL_STATUS:
    'Allow notifications for clinical review status',
  COMMON_SMS_MESSAGE: 'SMS text message',
  LABEL_ENABLE_FAMILY_SHARING: 'Enable family data sharing',
  LABEL_MAX_POCKET: 'Max Out of Pocket',
  LABEL_MAX_PAID_PLAN: 'Max Paid By Plan',
  LABEL_VIEW_PDF: 'VIEW PDF',
  LABEL_EOB_SELECT_YEAR: 'Select Year',
  LABEL_SHIP_TO_NAME: 'Ship to Name',
  COMMON_LABEL_CANCEL: 'Cancel',
  COMMON_LABEL_REVIEW: 'Review',
  LABEL_NOTIFICATIONS_MEDIA: 'EMAIL',
  LABEL_NOTIFICATIONS_COMMON_EMAIL: 'EMAIL',
  LABEL_PHI_SETTINGS_ENABLE_DATA_SHARING: 'Enable family data sharing',
  LABEL_BENEFITS_FAQS: 'BENEFITS FAQs',
  LABEL_CLINICAL_REVIEW_FAQS: 'CLINICAL REVIEW FAQs',
  LABEL_MAIL_ORDER_FAQS: 'MAIL ORDER FAQs',
  LABEL_PRIVACY_POLICY: 'PRIVACY POLICY',
  LABEL_TERMS_OF_USE: 'TERMS OF USE',
  LABEL_MORE_SETTINGS_ENABLE_FACE_ID: 'Enable Face ID',
  LABEL_MORE_SETTINGS_ENABLE_TOUCH_ID: 'Enable Touch ID',
  LABEL_MORE_SETTINGS_CHANGE_LANGUAGE: 'Change Language',
  LABEL_MORE_SETTINGS_SHIPPING_ADDRESS: 'Shipping Addresses',
  LABEL_MORE_SETTINGS_PAYMENT_METHODS: 'Payment Methods',
  LABEL_MORE_BENEFITS_FAQs: 'Benefits FAQs',
  LABEL_MORE_CLINICAL_REVIEW_FAQs: 'Clinical Review FAQs',
  LABEL_MORE_MAIL_ORDER_FAQs: 'Mail Order FAQs',
  LABEL_MORE_ABOUT_US: 'ABOUT US',
  LABEL_MORE_PRIVACY_POLICY: 'Privacy Policy',
  LABEL_MORE_TERMS_OF_USE: 'Terms of Use',
  LABEL_MORE_CUSTOMER_SUPPORT: 'Customer Support',
  TEXT_LOGOUT_ALERT: 'Are you sure you want to logout?',
  LABEL_MAPS_VIEW_IN_MAPS: 'View in maps',
  LABEL_COMMON_TODAY: 'Today - ',
  TITLE_CHANGE_LANGUAGE_SCREEN: 'CHANGE LANGUAGE',
  LABEL_CHANGE_LANGUAGE_CONFIRM: 'Confirm',
  LABEL_SETTINGS_PAYMENT_LIST_ADD_PAYMENT: 'ADD NEW PAYMENT METHOD',
  LABEL_SETTINGS_PAYMENT_DELETED: 'Payment method deleted successfully',
  LABEL_SETTINGS_PAYMENT_CONFIRM_DELETE:
    'Are you sure you want to delete this payment method?',
  LABEL_SETTINGS_PAYMENT_UPDATED: 'Payment method updated successfully',
  LABEL_MORE_APP_VERSION: 'App Version',
  LABEL_MORE_PHI_SETTINGS: 'PHI Settings',
  LABEL_MORE_FORMS: 'Forms',
  TITLE_PHI_SETTINGS: 'PHI SETTINGS',
  LABEL_PHI_SETTINGS_ENABLE_SPOUSE: 'Enable data sharing for',
  LABEL_PHI_SETTINGS_ENABLE_DEPENDENTS_SHARE: 'Enable data for dependents',
  LABEL_PHI_SETTINGS_CONFIRM: 'CONFIRM',
  LABEL_PHI_SETTINGS_SUCCESS: 'PHI settings updated successfully',
  LABEL_MORE_SETTINGS_NOTIFICATIONS: 'Notifications',
  TITLE_NOTIFICATIONS: 'NOTIFICATIONS',
  LABEL_CLAIM_DETAILS_PLAN_AMOUNT: 'Plan Amount',
  LABEL_FORMS_TITLE: 'FORMS',
  TITLE_UPDATE_PROFILE_FORM: 'UPDATE PROFILE',
  LABEL_UPDATE_PROFILE_FORM_FIRST_NAME: 'First Name',
  LABEL_UPDATE_PROFILE_FORM_LAST_NAME: 'Last Name',
  LABEL_UPDATE_PROFILE_FORM_DOB: 'Date of Birth',
  LABEL_UPDATE_PROFILE_FORM_CARD_ID: 'Card ID',
  LABEL_UPDATE_PROFILE_FORM_RXGRP: 'Rx GRP',
  LABEL_UPDATE_PROFILE_FORM_CONTACT_NUMBER: 'Contact Number',
  LABEL_UPDATE_PROFILE_FORM_EMAIL: 'E-mail',
  LABEL_CLAIM_DETAILS_MAINTENANCE: 'Maintenance',
  LABEL_CART_ADDRESS_CARE_OF: 'C/O',
  LABEL_DOWNLOAD_PDF_CONFIRMATION: 'Do you want to download this form?',
  TEXT_SUCCESSFUL_DOWNLOAD_PDF: 'Download was successful',
  CUSTOMER_SUPPORT_NUMBER_PRIMARY: '(866) 415-0222',
  CUSTOMER_SUPPORT_NUMBER_SECONDARY: '(857) 241-2439',
  LABEL_ABOUT_US: 'About Us',
  LABEL_UPDATE_PROFILE_SUBMIT: 'SUBMIT',
  ERROR_UPDATE_PROFILE_REQUIRED: 'Please fill in this field',
  ERROR_UPDATE_PROFILE_EMAIL: 'Please enter valid value',
  ERROR_UPDATE_PROFILE_FIRST_NAME: 'Please enter valid value',
  ERROR_UPDATE_PROFILE_LAST_NAME: 'Please enter valid value',
  ERROR_UPDATE_PROFILE_CONTACT: 'Please enter valid value',
  LABEL_UPDATE_PROFILE_SUCCESS: 'Profile updated successfully',
  MESSAGE_SEARCH_OTHER_DRUGS: 'Try searching other drug',
  ERROR_MESSAGE_NO_DATA_FOUND: 'Sorry, No Data Found',
  MESSAGE_FIND_INFO_PRICES: 'to find information and current prices',
  LABEL_DRUG_SEARCH: 'Use Drug Search',
  COMMON_LABEL_CONFIRMATION: 'Confirmation',
  LABEL_HOME_DELIVERY: 'HOME DELIVERY',
  LABEL_DEFAULT: '(Default)',
  LABEL_ADD_NEW_PAYMENT_METHOD: 'Add New Payment Method',
  LABEL_CREDIT_CARD_ADDED_SUCCESS: 'Credit card added successfully',
  LABEL_SEARCH_BY_ZIP: 'Search by Zip Code',
  LABEL_SEARCH_BY_CITY: 'Search by City',
  LABEL_LOADING: '...loading',
  ERROR_MSG_ENTER_VALID_VALUE: 'Please enter valid value',
  LABEL_PRICING_PAGE_MILES: 'pricing page - all miles value',
  ERROR_MSG_ENTER_QUANTITY: 'Please enter quantity',
  ERROR_MSG_VALID_QUANTITY: 'Please enter valid quantity',
  ERROR_MSG_ENTER_DAYS_SUPPLY: 'Please enter days supply',
  ERROR_MSG_VALID_DAYS_SUPPLY: 'Please enter valid days supply',
  LABEL_SEARCH: 'Search',
  LABEL_REQUIRED_FIELDS: 'Required Fields',
  LABEL_RETAIL: 'Retail',
  LABEL_MAIL_ORDER: 'Mail Order',
  LABEL_DISTANCE_MILES: 'Distance (in miles)',
  LABEL_BRAND_GENERIC: 'Brand/Generic',
  LABEL_FORMS: 'Forms',
  ERROR_MSG_NO_FORMS: 'No Forms Available',
  ERROR_MSG_ENTER_DIGITS: 'Please enter minimum 5 digits',
  MESSAGE_INCREASE_RADIUS: 'Try increasing search radius',
  LABEL_FAQS: 'FAQs',
  MESSAGE_NO_SAVED_ADDRESS: 'No saved address',
  MESSAGE_ADDRESS_DELETED_SUCCESS: 'Address deleted successfully',
  LABEL_NOTIFICATIONS: 'Notifications',
  MESSAGE_WHERE_TO_ENABLE_NOTIFICATIONS:
    'Where do you want to enable notifications?',
  LABEL_EMAIL: 'EMAIL',
  MESSAGE_NOTIFICATIONS_UPDATED_SUCCESS:
    'Notifications settings updated successfully',
  MESSAGE_NO_SAVED_PAYMENT_METHOD: 'No saved payment method',
  LABEL_ADD_NEW_CREDIT_CARD: 'Add New Credit Card',
  MESSAGE_ADD_PAYMENT_METHOD_SUCCESS: 'Payment method added successfully',
  MESSAGE_DELETED_PAYMENT_METHOD_SUCCESS: 'Payment method deleted successfully',
  LABEL_PHI_SETTINGS: 'PHI Settings',
  LABEL_ENABLE_DATA_SHARING: 'Enable data sharing',
  LABEL_ENABLE_DATA_SHARING_DEPENDENTS: 'Enable data for dependents',
  MESSAGE_PHI_UPDATED_SUCCESS: 'PHI settings updated successfully',
  test: 'testing',
  LABEL_CLAIMS_TOTAL_MEMBER_PAY: 'Total Member Pay',
  LABEL_CLAIMS_PLAN_AMOUNT: 'Plan Amount',
  LABEL_CLAIMS_RX_NUMBER: 'Rx Fill Number',
  LABEL_CO_NAME: 'C/O Name',
  ERROR_MSG_ENTER_CITY: 'Please enter city ',
  ERROR_MSG_ENTER_STATE: 'Please select state ',
  ERROR_MSG_ENTER_ZIPCODE: 'Please enter zip code ',
  ERROR_MSG_ENTER_ADDRESS1: 'Please enter addressLine1 ',
  LABEL_CREDIT_CARD_NO: 'Credit Card Number ',
  ERROR_MSG_ENTER_CREDIT_CARD_NO: 'Please enter valid Credit Card Number ',
  LABEL_DISCOVER: 'DISCOVER',
  'LABEL_MASTERCARD ': 'MASTERCARD',
  ERROR_MSG_SELECT_CARD_TYPE: 'Please select Credit Card Type ',
  LABEL_USE_DEFAULT: 'Use as Default',
  ERROR_MSG_ENTER_EXP_DATE: 'Please enter Expiration Date ',
  ERROR_MSG_ENTER_FIRST_NAME: 'Please enter First Name ',
  ERROR_MSG_ENTER_LAST_NAME: 'Please enter Last Name',
  LABEL_CAPS_EMPTY_MPP: 'No data available',
  LABEL_CAPS_EMPTY_MOOP: 'No data available',
  LABEL_CAPS_EMPTY_DEDUCTIBLE: 'No data available',
  LABEL_CREDIT_CARD_TYPE: 'Credit Card Type ',
  MESSAGE_TO_DELETE_ADDRESS:
    'Are you sure you want to delete this Shipping Address? ',
  MESSAGE_CONFIRM_LOGOUT: 'Are you sure that you want to logout?',
  MESSAGE_DELETE_ITEM_CART:
    'Are you sure you want to delete this item from cart?',
  MESSAGE_ORDER_SUCCESS: 'Your order has been placed successfully.',
  MENU_TERMS_OF_USE: 'Terms of Use',
  MESSAGE_ENTER_VALID_EMAIL: 'Please enter valid email',
  UPDATE_PROFILE_INFO_MSG:
    'Please verify your profile information and update as necessary.',
  MESSAGE_ENTER_EMAIL: 'Please enter email',
  MESSAGE_ENTER_CONTACT_NUMBER: 'Please enter contact number',
  LABEL_MORE_SETTINGS_ACCOUNT_PROFILE: 'Account Profile',
  ERROR_ADD_NEW_PAYMENT_ADDRESS_LINE_1: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_ADDRESS_LINE_2: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_CITY: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_CREDIT_CARD_NUMBER: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_CREDIT_CARD_TYPE: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_EXPIRATION_DATE: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_FIRST_NAME_ON_CARD: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_LAST_NAME_ON_CARD: 'Please enter a valid value',
  ERROR_ADD_NEW_PAYMENT_REQUIRED: 'Please fill in this field',
  ERROR_ADD_NEW_PAYMENT_ZIPCODE: 'Please enter a valid value',
  ERROR_COMMON_PATTERN_ERROR: 'Please enter a valid value',
  ERROR_COMMON_REQUIRED_ERROR: 'Please fill in this field',
  LABEL_ADD_NEW_PAYMENT_ADDRESS_LINE_1: 'Address Line 1',
  LABEL_ADD_NEW_PAYMENT_ADDRESS_LINE_2: 'Address Line 2',
  LABEL_ADD_NEW_PAYMENT_CITY: 'City',
  LABEL_ADD_NEW_PAYMENT_CREDIT_CARD_NUMBER: 'Credit Card Number',
  LABEL_ADD_NEW_PAYMENT_CREDIT_CARD_TYPE: 'Credit Card Type',
  LABEL_ADD_NEW_PAYMENT_DEFAULT_CARD_INDICATOR: 'Use as Default',
  LABEL_ADD_NEW_PAYMENT_EXPIRATION_DATE: 'Expiration Date',
  LABEL_ADD_NEW_PAYMENT_FAILED: 'Sorry, something went wrong',
  LABEL_ADD_NEW_PAYMENT_FIRST_NAME_ON_CARD: 'First Name',
  LABEL_ADD_NEW_PAYMENT_LAST_NAME_ON_CARD: 'Last Name',
  LABEL_ADD_NEW_PAYMENT_STATE: 'State',
  LABEL_ADD_NEW_PAYMENT_SUBMIT: 'CONFIRM',
  LABEL_ADD_NEW_PAYMENT_SUCCESS: 'Payment method added successfully',
  LABEL_ADD_NEW_PAYMENT_ZIPCODE: 'ZIP Code',
  LABEL_CART_ADDRESS_NOT_FOUND: 'No saved address',
  LABEL_CART_PAYMENT_ADD: 'ADD NEW PAYMENT',
  LABEL_CART_PAYMENT_ADD_PAYMENT_TO_CONTINUE: 'ADD PAYMENT TO CONTINUE',
  LABEL_CART_PAYMENT_NOT_FOUND: 'No saved payment method',
  LABEL_CHANGE_LANGUAGE_SUCCESS: 'Idioma actualizado correctamente',
  LABEL_COMMON_REQUIRED_FELIDS: '* Required Fields',
  LABEL_DRUG_PRICING_DETAILS_BRAND: 'Brand',
  LABEL_DRUG_PRICING_DETAILS_GENERIC: 'Generic',
  LABEL_DRUG_PRICING_HEADING: 'DRUG PRICING',
  LABEL_LINK_FAILED: 'Sorry, link cannot be opened',
  LABEL_NETWORK_ERROR_ON_SUBMIT: 'Please check your internet connection',
  LABEL_PASSWORD_CHANGED_SUCCESSFULLY: 'Password changed successfully',
  LABEL_SESSION_CONTINUE: 'Continue',
  LABEL_SESSION_EXPIRED: 'Please login again to continue',
  LABEL_SESSION_EXPIRED_CONFIRM: 'Ok',
  LABEL_SESSION_EXPIRED_FACE_ID: 'Please, provide Face ID again to continue',
  LABEL_SESSION_EXPIRED_TOUCH_ID: 'Please, provide Touch ID again to continue',
  LABEL_SESSION_LOGOUT: 'Logout',
  LABEL_SETTINGS_PAYMENT_LIST_NOT_FOUND: 'No saved payment method',
  LABEL_SETTINGS_SHIPPING_ADDRESS_CONFIRM_DELETE:
    'Are you sure you want to delete this address?',
  LABEL_SETTINGS_SHIPPING_ADDRESS_DELETED: 'Address deleted successfully',
  LABEL_SHIPPING_ADDRESS_NOT_FOUND: 'No saved address',
  TITLE_ADD_PAYMENT: 'ADD NEW PAYMENT',
  TITLE_SESSION_EXPIRED: 'Session expired',
  TITLE_UPDATE_PROFILE_IS_ACCOUNT: 'ACCOUNT PROFILE',
  TITLE_PLANNED_OUTAGE: "SCHEDULED MAINTENANCE",
  LABEL_ERROR_MODAL_TRY_AGAIN: 'Try Again',
  LABEL_ERROR_MODAL_HOME: 'HOME',
  LABEL_ERROR_CANCEL: 'CANCEL',
  LABEL_ORDER_PLACEMENT_FAILED: 'Sorry, Could not place the order',
  ERROR_UPDATE_PROFILE:
    'You can try updating your profile through settings later.',
  LABEL_ELIGIBILITY_FAILED:
    "Sorry, we're having difficulties confirming your membership. Please refer to phone number on the back of your ID card for assistance.",
  LABEL_CONTACT_VALIDATION: 'Contact number should be 10 digits',
  LABEL_UPDATE_PROFILE_SUCCESS_MSG:
    'Your profile has been updated successfully',
  LABEL_NOTIFICATIONS_CONFIRM: 'CONFIRM',
  ERROR_ADD_NEW_ADDRESS_ZIP_CODE: 'Please enter a valid value',
  PRESCRIPTION_RECENT: 'Recent',
  PRESCRIPTION_HISTORY: 'History',
  ORDERS_PAST: 'Past Orders',
  ORDERS_RECENT: 'Recent Orders',
  BUTTON_UPDATE_PROFILE_SEND_VERIFICATION_CODE: 'SEND VERIFICATION CODE',
  BUTTON_UPDATE_PROFILE_VERIFY_CODE: 'VERIFY CODE',
  BUTTON_UPDATE_PROFILE_SEND_AGAIN: 'SEND NEW CODE',
  BUTTON_MORE_UPDATE: 'UPDATE',
  ANDROID_PLAY_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.empirx.health',
  APPLE_APP_STORE_URL: 'itms://itunes.apple.com/in/app/apple-store/1238289255',
  LABEL_UPDATE_PROFILE_FORM_VERIFICATION_CODE: 'Verification Code',
  ERROR_UPDATE_PROFILE_FORM_VERIFICATION_CODE:
    'Please enter a valid 6-digit verification code',
  LABEL_HOME_UPDATE_SKIP: 'Skip',
  LABEL_HOME_UPDATE_IMMEDIATE:
    'The version of your app is out of date and may not work properly, please update immediately.',
  NOTIFICATIONS_DATA_CHARGES_MSG:
    'You are now enrolled in EmpiRx Health SMS notifications. Please note that message and data rates may apply.',
  LABEL_HOME_UPDATE_NOW:
    'You are now enrolled in EmpiRx Health SMS notifications. Please note that message and data rates may apply.',
  LABEL_APP_BLOCKED_MESSAGE:
    'The version of your app is no longer supported. Please update to the latest version to continue using the app services.',
  BUTTON_APP_BLOCKED_UPDATE: 'UPDATE NOW',
  LABEL_MORE_CLINICAL_REVIEW: 'Clinical Reviews',
  TITLE_CLINICAL_REVIEW_HEADER: 'CLINICAL REVIEWS',
  LABEL_NO_CLINICAL_REVIEWS: 'No clinical reviews found',
  LABEL_CLINICAL_REVIEW_SOMETHING_WRONG: 'Sorry, something went wrong',
  LABEL_CLINICAL_REVIEW_INQUIRY_ID: 'Inquiry ID',
  LABEL_CLINICAL_REVIEW_CREATED_DATE: 'Date Created',
  LABEL_CLINICAL_REVIEW_REQUESTED_BY: 'Requested By',
  LABEL_CLINICAL_REVIEW_STATUS: 'Status',
  LABEL_CLINICAL_REVIEW_FIRST_NAME: 'Member First Name',
  LABEL_CLINICAL_REVIEW_LAST_NAME: 'Member Last Name',
  LABEL_CLINICAL_REVIEW_DRUG_NAME: 'Drug Name',
  LABEL_CLINICAL_REVIEW_STATUS_DESCRIPTION: 'Status Description',
  LABEL_CLINICAL_REVIEW_PDF_NAME: 'Clinical Review',
  TITLE_CLINICAL_REVIEW_DETAILS: 'CLINICAL REVIEW DETAILS',
  LABEL_CLINICAL_REVIEW_DOWNLOAD_CONFIRMATION:
    'Do you want to download this document?',
  LABEL_FILTER_SORT_EARLIEST_FIRST: 'Ascending',
  LABEL_FILTER_SORT_OLDEST_FIRST: 'Descending',
  LABEL_FILTER_SORT_NONE: 'None',
  LABEL_CLINICAL_REVIEW_FILTER_MEMBER: 'Filter by Member',
  LABEL_CLINICAL_REVIEW_SORT_DATE: 'Sort by Date',
  LABEL_CLINICAL_REVIEW_MEMBER_NAME: 'Member Name',
  LABEL_GET_MOBILE_NUMBER_FAILED: 'Failed to fetch mobile number',
  LABEL_MOBILE_NUMBER_UPDATE_SUCCESS: 'Mobile number added successfully',
  LABEL_MOBILE_NUMBER_UPDATE: 'Mobile Number Update',
  LABEL_MOBILE_NUMBER: 'Mobile Number',
  ERROR_MSG_MOBILE_NUMBER_IMP: 'Please enter mobile number',
  ERROR_MSG_MOBILE_NUMBER_VALIDATION_DIGITS:
    'Mobile number should be 10 digits',
  LABEL_MOBILE_NUMBER_UPDATE_SUBMIT: 'SUBMIT',
  LABEL_MORE_CLINICAL_REVIEW_ITEM: "Reviews",
  LABEL_MORE_CLINICAL_REVIEW_NOTIFICATION: "Notification",
  // LABEL_GET_MOBILE_NUMBER_FAILED: "Failed to fetch mobile number",
  LABEL_MORE_MAIL_ORDER: "Mail Order",
  LABEL_MORE_MAIL_ORDER_NOTIFICATION: "Notification",
  TITLE_MAIL_ORDER_CLINICAL_NOTIFICATION: "NOTIFICATION",
  TITLE_MAIL_ORDER_NOTIFICATION: "NOTIFICATION",

  // GUEST screen Labels
  LABEL_BOTTOM_GUEST_SCREEN: "Guest Screen",
  LABEL_BOTTOM_PRIVACY_POLICY: "Privacy Policy",
  LABEL_BOTTOM_TERMS_OF_USE: "Terms Of Use",
  LABEL_BOTTOM_ABOUT_US: "About Us",
  LABEL_GUEST_ERROR_SORRY: "Sorry, something went wrong",
  LABEL_GUEST_TECHNICAL_SUPPORT: "Technical Support",
  LABEL_GUEST_CUSTOMER_SUPPORT: "Customer Support",
  DATA_GUEST_TECHNICAL_SUPPORT: "(866) 415-0222",
  DATA_GUEST_CUSTOMER_SUPPORT: "(877) 241-7123",
  LABEL_GUEST_DOWNLOAD_FORMS: "Download Forms",
  LABEL_GUEST_FAQS: "FAQs",
  LABEL_GUEST_MAIL_ORDER_FAQS: "Mail Order FAQs",
  LABEL_GUEST_BENEFITS_FAQS: "Benefits FAQs",
  LABEL_GUEST_CLINICAL_FAQS: "Clinical Review FAQs",
  LABEL_GUEST_BANNER_HOME: "Welcome to EmpiRx Health. We make managing prescriptions easy online, anytime",
  LABEL_GUEST_CAROUSEL_BENEFITS_BANNER: "Get the most from your pharmacy benefit",
  LABEL_GUEST_CAROUSEL_PHARMACY_SEARCH_BANNER: "Search for a nearby pharmacy",
  LABEL_GUEST_CAROUSEL_PRESCRIPTIONS_HISTORY_BANNER: "View prescription history, for you or a family member",
  LABEL_GUEST_CAROUSEL_MAIL_ORDER_BANNER: "Easily refill mail order prescriptions",
  LABEL_GUEST_CAROUSEL_DRUG_PRICING_BANNER: "Find the best price for your medication",
  LABEL_GUEST_LOGIN_BUTTON: "LOGIN",
  LABEL_GUEST_CREATE_ACCOUNT_BUTTON: "CREATE AN ACCOUNT",
  LABEL_GUEST_OR: "or",
  LABEL_GUEST_LOGIN_MESSAGE: "for full access to",
  LABEL_GUEST_USER: "Guest User",
  LABEL_GUEST_LOGIN_MESSAGE_SUB_TEXT: "MyEmpiRxHealth",
  TITLE_GUEST_CUSTOMER_SUPPORT: "Member Services",
  LABEL_GUEST_FULL_ACCESS: "Login or create an account for full access to",
  LABEL_GUEST_ACCOUNT_SUB_TEXT: "Already have an account?",
  LABEL_GUEST_FIRST_TIME: "First time here?",


  // accessibility 
  LABEL_TEXT_SIZE: "TEXT SIZE",
  TEXT_SIZE_SMALL: "Small",
  TEXT_SIZE_MEDIUM: "Medium",
  TEXT_SIZE_LARGE: "Large",
  LABEL_TEXT_SIZE_UPDATED: "Text size updated successfully",
  SELECT_LANG_LABEL: "Select a language",
  LABEL_SELECT_TEXT: "Select a text size",
  LABEL_OPEN_PROFILE: "Open profile menu",
  LABEL_COLLAPSE_MENU: "Collapse the side navigation menu",
  LABEL_OPEN_CART: "Open the cart to order selected prescriptions",
  LABEL_REMOVE_PRESCRIPTION: "Remove the prescription from cart",
  LABEL_DOWNLOAD_PRESCRIPTION: "Download the prescription details",
  LABEL_OPEN_PDF: "Click here to open PDF document in new tab",
  LABEL_DOWNLOAD_DRUG_DETAILS: "Download drug details PDF",
  LABEL_DRUG_SEARCH_RESULT: "Drug search results",
  LABEL_VIEW_DRUG_DETAILS: "View drug details",
  LABEL_MAP_VIEW: "Switch to map view",
  LABEL_LIST_VIEW: "Switch to list view",
  LABEL_CLEAR_DRUG: "Clear drug name",
  LABEL_ADDRESS_LIST: "List of shipping addresses",
  LABEL_DELETE_ADDRESS: "Delete the selected address",
  LABEL_DELETE_DEFAULT_CARD: "Delete the default card",
  LABEL_PAYMENT_LIST: "List of other payment methods",
  LABEL_DELETE_CARD: "Delete the selected card",
  LABEL_CLOSE_DIALOG: "Close the dialog",
  LABEL_APPLY_FILTER: "Apply a filter",
  LABEL_CLOSE_FILTER: "Close the filter menu",
  LABEL_GUEST_MEMBER_SERVICES_SUPPORT: "Refer to phone number on the back of your ID card",
  LABEL_HEADER_FUTURE_EFFECTIVE_TEXT: "Some of the features may be unavailable until your membership becomes eligible.",

  CART_CHECKOUT_PAYMENT_CARD_ON_FILE: "Credit card on file will be charged.",// Se cargará la tarjeta de crédito registrada.
  DRUG_PRICING_DEDUCTIBLE_AMT_APPLIED: "Deductible Amount Applied", // Monto del deducible aplicado
  DRUG_PRICING_DEDUCTIBLE_PRIOR: "Deductible Post",// Publicación del deducible
  DRUG_PRICING_DEDUCTIBLE_POST: "Deductible Prior",// Deducible previo
  HOME_LABEL_CONTACTING_PRESCRIBER: "Contacting Prescriber",// Cómo contactar al prescriptor


  LABEL_MAIL_ORDER_DIALOG_TITLE: '"Get started with our mail order pharmacy."',
  LABEL_MAIL_ORDER_DIALOG_SUB_TITLE_1: 'Our goal is to make your pharmacy care convenient, easy, and personalized. Please update the profiles to take advantage of our mail order pharmacy service.',
  LABEL_MAIL_ORDER_DIALOG_SUB_TITLE_2: 'Alternatively, you can opt to ‘Update later’ from the ‘Family Profiles’ section.',
  LABEL_MAIL_ORDER_DIALOG_UPDATE: 'Update Now',
  LABEL_MAIL_ORDER_DIALOG_SKIP: 'Update Later',

  LABEL_PMART_ENROLLMENT_FORM_TITLE: 'MAIL ORDER PHARMACY',
  LABEL_PMART_ENROLLMENT_FORM_HEADING: '"Get started with our mail order pharmacy.”',
  LABEL_PMART_ENROLLMENT_FORM_NOTE: 'Our goal is to make your pharmacy care convenient, easy, and personalized. Please fill out this profile below to take advantage of our mail order pharmacy service.',
  LABEL_PMART_ENROLLMENT_FORM_CONTACT_INFO: 'Contact Information',
  LABEL_PMART_ENROLLMENT_FORM_FIRST_NAME: 'First Name',
  LABEL_PMART_ENROLLMENT_FORM_LAST_NAME: 'Last Name',
  LABEL_PMART_ENROLLMENT_FORM_DOB: 'Date of Birth',
  LABEL_PMART_ENROLLMENT_FORM_GENDER: 'Gender',
  LABEL_PMART_ENROLLMENT_FORM_EMAIL: 'Email',
  LABEL_PMART_ENROLLMENT_FORM_CONTACT_NUMBER: 'Contact Number',
  LABEL_PMART_ENROLLMENT_FORM_HOME_PHONE: 'Home Phone',
  LABEL_PMART_ENROLLMENT_FORM_NOTIFICATION_TEXT: 'I want notifications on',
  LABEL_PMART_ENROLLMENT_FORM_NOTIFICATION_OPTION_TEXT: 'Text',
  LABEL_PMART_ENROLLMENT_FORM_NOTIFICATION_OPTION_EMAIL: 'Email',
  LABEL_PMART_ENROLLMENT_FORM_ADDRESS: 'Address',
  LABEL_PMART_ENROLLMENT_FORM_ADD_NEW: 'Add New',
  LABEL_PMART_ENROLLMENT_FORM_ADD_FROM_SHIPPING_ADDRESS: 'Add from Shipping Address',

  LABEL_PMART_ENROLLMENT_FORM_STREET_1: 'Street Address',
  LABEL_PMART_ENROLLMENT_FORM_STREET_2: 'Street Address Line 2',
  LABEL_PMART_ENROLLMENT_FORM_CITY: 'City',
  LABEL_PMART_ENROLLMENT_FORM_STATE: 'State / Province',
  LABEL_PMART_ENROLLMENT_FORM_ZIP_CODE: 'Postal / Zip Code',

  LABEL_PMART_ENROLLMENT_FORM_PRIMARY_LANGUAGE: 'Primary Language',
  LABEL_PMART_ENROLLMENT_FORM_ENGLISH: 'English',
  LABEL_PMART_ENROLLMENT_FORM_SPANISH: 'Spanish',
  LABEL_PMART_ENROLLMENT_FORM_OTHER: 'Other',
  LABEL_PMART_ENROLLMENT_FORM_MEDICAL_INFO: 'Medical Information',
  LABEL_PMART_ENROLLMENT_FORM_DRUG_ALLERGIES: 'Drug Allergies?',
  LABEL_PMART_ENROLLMENT_FORM_ALLERGIES: 'Allergies',
  LABEL_PMART_ENROLLMENT_FORM_NO: 'No',
  LABEL_PMART_ENROLLMENT_FORM_YES: 'Yes',
  LABEL_PMART_ENROLLMENT_FORM_MEDICAL_CONDITIONS: 'Medical Conditions?',
  LABEL_PMART_ENROLLMENT_FORM_LIST_MEDICAL_CONDITIONS: 'List Medical Conditions',
  LABEL_PMART_ENROLLMENT_FORM_OTHER_MEDICATIONS: 'Other Medications (including over the counter medications)',
  LABEL_PMART_ENROLLMENT_FORM_ADD_MORE: 'ADD MORE',
  LABEL_PMART_ENROLLMENT_FORM_NEED_CONTROLLED_SUBSTANCES: "Think you'll need controlled substances?",
  LABEL_PMART_ENROLLMENT_FORM_INSURANCE_INFO: 'Insurance Information',
  LABEL_PMART_ENROLLMENT_FORM_MEMBER_ID: 'Member ID Number',
  LABEL_PMART_ENROLLMENT_FORM_GROUP_ID: 'Group ID',
  LABEL_PMART_ENROLLMENT_FORM_DRIVER_LICENSE: 'Driver License Number',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS: 'New Prescriptions or Transfer(s)',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_TEXT2: 'How will we get your prescriptions?',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_OPTION1: 'I will have my doctor send you new prescriptions',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_OPTION2: 'Fax my doctor for new prescriptions',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_OPTION3: 'Transfer my existing prescriptions from  another pharmacy',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_OPTION4: 'How will we get your prescriptions?',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_SUBTEXT_1: 'Note',
  LABEL_PMART_ENROLLMENT_FORM_NEW_PRESCRIPTIONS_SUBTEXT_2: 'We will NOT request new narcotic prescriptions from your provider. The provider must submit those at your request.',
  LABEL_PMART_ENROLLMENT_FORM_PRESCRIBER: 'Prescriber',
  LABEL_PMART_ENROLLMENT_FORM_PHONE: 'Phone',
  LABEL_PMART_ENROLLMENT_FORM_FAX: 'Fax',
  LABEL_PMART_ENROLLMENT_FORM_PRESCRIPTION_NAME: 'Prescription Name',
  LABEL_PMART_ENROLLMENT_FORM_PRESCRIPTION_STRENGTH: 'Prescription Strength',

  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_1: 'New Prescriptions?',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_2: 'Prescribers can send prescriptions using their electronic system to:',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_3: 'Prescription Mart',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_4: '4144 Dowlen Rd',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_5: 'Beaumont TX, 77706',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_6: 'Phone: 800-713-1230',
  LABEL_PMART_ENROLLMENT_FORM_DOC_PRESCRIPTIONS_TEXT_7: 'Fax: 409-866-1317',

  LABEL_PMART_ENROLLMENT_FORM_CURRENT_PHARMACY: 'Current Pharmacy',
  LABEL_PMART_ENROLLMENT_FORM_PHARMACY_PHONE: 'Pharmacy Phone',
  LABEL_PMART_ENROLLMENT_FORM_PRESCRIPTIONS: 'Prescriptions',
  LABEL_PMART_ENROLLMENT_FORM_SIGNATURE_SUFFIX: 'Enter First and Last Name',
  LABEL_PMART_ENROLLMENT_FORM_SIGNATURE: 'Signature',
  LABEL_PMART_ENROLLMENT_FORM_ADDITIONAL_COMMENTS: 'Additional Comments',
  LABEL_PMART_ENROLLMENT_FORM_PRIVACY_POLICY_1: 'I acknowledge the pharmacy',
  LABEL_PMART_ENROLLMENT_FORM_PRIVACY_POLICY_2: 'privacy policies',
  LABEL_PMART_ENROLLMENT_FORM_BILL_RIGHTS_1: 'I have viewed the',
  LABEL_PMART_ENROLLMENT_FORM_BILL_RIGHTS_2: 'Patient Bill of Rights and Responsibilities',
  LABEL_FAMILY_PROFILE_PMART_PROFILE_UPDATE: 'Our goal is to make your pharmacy care convenient, easy, and personalized. Please update the profile to take advantage of our mail order pharmacy service.',
  LABEL_FAMILY_PROFILE_PMART_PROFILE_REQUIRED_MESSAGE: 'This field is required',
  LABEL_FAMILY_PROFILE_PMART_PROFILE_MOBILE_NUMBER_VALIDATION_DIGITS: 'Home Phone should be 10 digits',
  LABEL_FAMILY_PROFILE_PMART_PROFILE_ZIP_CODE_LENGTH_VALIDATION: 'Zip Code should be 5 digits',
  LABEL_SIGNATURE_NOTE: 'Signature should match with your first name and last name.',
  LABEL_COMMON_DELETE: 'DELETE',
  LABEL_PRESCRIPTION_UPDATE_PROFILE: 'To take advantage of our mail order pharmacy service, please create your Prescription Mart profile.',
  LABEL_PRESCRIPTION_UPDATE_BUTTON: 'Update',
  LABEL_PMART_ENROLLMENT_FORM_NO_SHIPPING_ADDRESS_MESSAGE: 'No shipping addresses found.',

  LABEL_NOTIFICATIONS_REFILL: "Allow notifications for refill reminders",
  LABEL_NOTIFICATIONS_SHIPPING: "Allow notifications for shipping updates",
  LABEL_COMPLETE_PROFILE_DIALOG_TITLE: '"Please complete the profile to "Refill/Renew" prescriptions"',
  LABEL_HOME_CANCELLED: 'Cancelled',

  LABEL_FAQ_SEARCH_MESSAGE: 'Enter minimum 3 characters',
  LABEL_FAQ_NO_SEARCH_FOUND: 'No results found for',

  LABEL_PMART_ENROLLMENT_FORM_PRESCRIPTION_NOTICE: 'Prescription Mart may substitute FDA-approved generic medications for brand name medications unless you or your prescriber specify otherwise. If you DO NOT want generic medications, you must provide specific instructions (including drug names) below. Refusal of generics may impact your copay.',

  LABEL_CANT_FIND_PAST_ORDERS: 'Can’t find Past Orders?',
  LABEL_CANT_FIND_PAST_CLAIMS: 'Can’t find Past Claim?',
  LABEL_CANT_FIND_PAST_PRESCRIPTION: 'Can’t find Past Prescription?',

  LABEL_REQUEST_BUTTON: 'Request',

  LABEL_REQUEST_HISTORICAL_DATA_FOR_ORDER_TITLE: 'Request Past Orders',
  LABEL_REQUEST_HISTORICAL_DATA_FOR_CLAIM_TITLE: 'Request Past Claim',
  LABEL_REQUEST_HISTORICAL_DATA_FOR_PRESCRIPTION_TITLE: 'Request Past Prescription',

  LABEL_REQUEST_HISTORICAL_DATA_MESSAGE: 'Please check back for the requested data. Your request may take up to 5 business days',
  LABEL_REQUEST_HISTORICAL_DATA_BUTTON: 'SEND',
  LABEL_REQUEST_HISTORICAL_DATA_CANCEL_BUTTON: 'CANCEL',

  LABEL_REQUEST_HISTORICAL_DATA_FOR_SELECT_YEAR: 'Reason',
  LABEL_REQUEST_HISTORICAL_DATA_FOR_REASON: 'Select Year',
  LABEL_BUTTON_OK: 'OK',

  LABEL_SELECT_YEAR_REQUIRED_MESSAGE: 'Please select year',
  LABEL_ENTER_REASON_REQUIRED_MESSAGE: 'Please enter reason',
  LABEL_MEMBERSHIP_DETAILS_PMART_FORM_COMPLETED_HEADING: "Your mail order profile has been updated",
  LABEL_MEMBERSHIP_DETAILS_PMART_FORM_COMPLETED_MSG: "Your mail order profile has been updated. You can now take advantage of our mail order pharmacy service.",

  LABEL_HOME_PRESCRIPTION_LIST_NOTE: "Please note, prescriptions listed here are mail order ONLY.",
  LABEL_HOME_PRESCRIPTION_LIST_NO_ACTIVE: "You have no recent prescriptions.",
  LABEL_HOME_PRESCRIPTION_LIST_VIEW_HISTORY: "VIEW HISTORY",
  LABEL_HOME_CLAIMS_LIST_NO_CLAIMS:"You have no claims for current year.",
  TITLE_PRESCRIPTION_ACTION_CONFIRMATION: "CONFIRMATION",
  LABEL_BUTTON_CONFIRM : "CONFIRM",
  LABEL_BUTTON_CANCEL : "CANCEL",
  LABEL_PRESCRIPTION_ACTION_ENABLE_AUTO_REMINDER: "Are you sure you want to enable <b>Refill Reminder</b> for this prescription?",
  LABEL_PRESCRIPTION_ACTION_DISABLE_AUTO_REMINDER: "Are you sure you want to disable <b>Refill Reminder</b> for this prescription?",
  LABEL_PRESCRIPTION_ACTION_DISABLE_AUTO_CHARGE_SHIP: "Are you sure you want to disable <b>Auto Charge & Ship</b> for this prescription?",
  LABEL_PRESCRIPTION_ACTION_ATTESTATION_CHECKBOX: "I have read and agree to the terms and conditions of the Auto Refill Program.",
  LABEL_PRESCRIPTION_DETAILS_SPECIALTY_DRUG: "Specialty Drug",
  LABEL_PRESCRIPTION_DETAILS_AUTO_REFILL_REMINDER:"Refill Reminder",
  LABEL_PRESCRIPTION_DETAILS_AUTO_REFILL_CHARGE_SHIP:"Auto Charge & Ship",
  LABEL_PRESCRIPTION_COLUMN_REFILL_OPTION : "Refill Options",
  LABEL_PRESCRIPTION_COLUMN_REFILL_REMINDER :"Refill Reminder",
  LABEL_PRESCRIPTION_TOOLTIP_REMINDER: "Refill Reminder",
  LABEL_PRESCRIPTION_TOOLTIP_AUTO_CHARGE_SHIP: "Auto Charge & Ship",
  LABEL_PRESCRIPTION_REMINDER_ICON_DESC: "Refill Reminder",
  LABEL_PRESCRIPTION_REMINDER_ICON_CHARGE_SHIP: "Auto Charge & Ship",
  LABEL_PRESCRIPTION_QTY: 'Qty',
  LABEL_PAYMENT_CREDIT_CARD_ERROR_MSG : "Please enter valid expiry date.",
  LABEL_COMMON_CREATE_BUTTON: "Create",

  TOOLTIP_PRESCRIPTION_REMINDER_NOT_AVAILABLE: "Refill reminder is not available for this prescription.",
  TOOLTIP_PRESCRIPTION_CHARGE_SHIP_NOT_AVAILABLE: "Auto charge & ship is not available for this prescription.",
  TOOLTIP_PRESCRIPTION_REMINDER_AND_CHARGE_SHIP_NOT_AVAILABLE: "Neither the refill reminder nor auto charge and ship are available for this prescription.",
  LABEL_COMMON_FILTER_BY_YEAR: 'Filter by Year',

  LABEL_PHI_SETTINGS_ENABLE_DATA_SHARING_TITLE: 'Family Data Sharing',
  LABEL_PHI_SETTINGS_ENABLE_DATA_SHARING_SUBTITLE:
    'Please choose family members to share the data with. Only registered family members on EmpiRx Member Portal can view the details.',
  LABEL_PHI_SETTINGS_ENABLE_USER: 'Share My Data',
  LABEL_PHI_SETTINGS_ENABLE_DATA_SHARING_TOOLTIP:
    'This member is not registered on the EmpiRx Member Portal. Once they are registered, you will be able to share PHI data with them.',
  LABEL_PHI_SETTINGS_SHARE_DEPENDENT: "Share Minors' Data",
  LABEL_MEMBERSHIP_DETAILS_INFO_HEADER: 'Info', // used on mobile
  LABEL_MEMBERSHIP_DETAILS_UNAVAILABLE: 'This member has not shared health information (PHI) with you.',
  LABEL_MEMBERSHIP_DETAILS_MINOR_UNAVAILABLE: "Cardholder has not shared this member's health information (PHI) with you.",
  LABEL_MEMBERSHIP_DETAILS_MEMBER_LIST: 'List Of Members',
};
